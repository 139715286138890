import { Template } from './../../Stampe/Models/Template';
import { BehaviorSubject } from 'rxjs';
import { ApiRestService } from './api-rest.service';
import { Injectable } from '@angular/core';
import { Files } from '../models/File';
import { ApiPathsService } from 'src/app/api-paths.service';

@Injectable({
  providedIn: 'root'
})
export class FileStoreService {

  constructor(private apiRestService: ApiRestService,
    private apiPaths: ApiPathsService,
  ) { }
  private LISTA_FILE = new BehaviorSubject<Files[]>([]);
  private FILE = new BehaviorSubject<Files>(null);
  private URL = new BehaviorSubject<string>(null);
  private ID = new BehaviorSubject<string>(null);
  private SEZIONE = new BehaviorSubject<string>(null);
  private dataStore: {
    ListaFile: Files[],
    File: Files,
    Url: string,
    Sezione: string,
    Id: string,
  } = {
      ListaFile: [],
      File: null,
      Url: null,
      Sezione: null,
      Id: null,
    }
  readonly $listaFile = this.LISTA_FILE.asObservable();
  readonly $file = this.FILE.asObservable();
  readonly $url = this.URL.asObservable();
  readonly $Sezione = this.SEZIONE.asObservable();
  readonly $id = this.ID.asObservable();
  refreshListaFile = () => this.LISTA_FILE.next(Object.assign({}, this.dataStore).ListaFile);
  refreshFile = () => this.FILE.next(Object.assign({}, this.dataStore).File);
  refreshUrl = () => this.URL.next(Object.assign({}, this.dataStore).Url);
  refreshSezione = () => this.SEZIONE.next(Object.assign({}, this.dataStore).Sezione);
  refreshId = () => this.ID.next(Object.assign({}, this.dataStore).Id);
  SetListaFile(data) {
      this.dataStore.ListaFile = data;
      this.refreshListaFile();
  }
  updateFile(file: Files) {
    return this.apiRestService.updateFile(file)
  }
  updateSelectedFile(file: Files) {
    this.dataStore.File = file;
    this.refreshFile();
  }
  SelectSection(sezione: string) {
    this.dataStore.Sezione = sezione;
    this.refreshSezione();
  }
  SelectId(value: number) {
    if(value)
      this.dataStore.Id = value.toString();
    else
      this.dataStore.Id = undefined;
    this.refreshId();
  }
  deleteFile(file: Files | Template) {
    return this.apiRestService.deleteFile(file);
  }
  updateSelectedUrl(url: string) {
    this.dataStore.Url = this.apiPaths.getBaseAPI() + url;
    this.refreshUrl();
  }

}
