import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "../_services/authentication.service";
import {environment} from "../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
      private router: Router,
      private  authenticationService: AuthenticationService
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this.authenticationService.currentUserValue;
      if (currentUser)
        return true;
      this.router.navigate(['/Login'], { queryParams: { returnUrl: state.url }});
      return false;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateTask implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Task;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateShared implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Shared;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateProgetti implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Progetti || environment.Modules.Prodotti;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateStampe implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Stampe;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateInterventi implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Interventi;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateScadenzario implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Scadenzario;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivatePrimaNota implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Prima_Nota;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateSpedizioni implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Spedizioni;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateSettings implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Settings;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateFormazione implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Formazione;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateTicket implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Ticket;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateEcommerce implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Ecommerce;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateRma implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Rma;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateListini implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Listini;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateAcquisti implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Acquisti;
  }
}
@Injectable({ providedIn: 'root' })
export class ActivateAdmin implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return environment.Modules.Admin;
  }
}

